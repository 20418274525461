import React from "react"
import { Box, Container, Typography, Grid } from "@mui/material"
import Footer from "./Footer"

const AboutUsPage = () => {
	return (
		<>
			<Box
				sx={{
					backgroundColor: "#f5f5f5", // Light background for readability
					color: "#333",
					py: { xs: 6, md: 8 },
					px: 3,
				}}>
				<Container maxWidth="md">
					<Typography
						variant="h4"
						align="center"
						gutterBottom
						sx={{ color: "#1976d2", fontWeight: "bold" }}>
						Arthgyan Welcomes You to the World of Financial
						Tranquility
					</Typography>
					<Typography variant="body1" align="center" sx={{ mb: 4 }}>
						At Arthgyan, we are committed to providing you with the
						most feasible solutions to your day-to-day economic
						necessities.
					</Typography>

					<Grid container spacing={4}>
						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									backgroundColor: "#89CFF0",
									color: "black",
									borderRadius: "8px",
									p: 3,
								}}>
								<Typography
									variant="h6"
									gutterBottom
									sx={{ fontWeight: "bold", mb: 2 }}>
									Tailored for Government Employees
								</Typography>
								<Typography variant="body2">
									We offer a single platform shaped for your
									diverse needs, from insurance to investments
									and banking.
								</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Box
								sx={{
									backgroundColor: "#FF8C00",
									color: "white",
									borderRadius: "8px",
									p: 3,
								}}>
								<Typography
									variant="h6"
									gutterBottom
									sx={{ fontWeight: "bold", mb: 2 }}>
									Exciting Rewards and Benefits
								</Typography>
								<Typography variant="body2">
									We add value to your banking and investments
									with rewards like movie tickets, and much
									more.
								</Typography>
							</Box>
						</Grid>
					</Grid>

					<Typography
						variant="body1"
						align="center"
						sx={{
							mt: 5,
							fontSize: "1rem",
							lineHeight: 1.8,
						}}>
						Experience the thrill of joyful investments with our
						trusted services.
						<br />
						We color your life with trustworthy hues of financial
						security and benefits, offering exciting options and
						services to explore.
					</Typography>
				</Container>
			</Box>
			<Footer />
		</>
	)
}

export default AboutUsPage
