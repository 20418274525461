import React from "react"
import { useNavigate } from "react-router-dom"
import {
	Container,
	Typography,
	Button,
	Grid2,
	Card,
	CardContent,
	CardMedia,
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@mui/material"
import { styled } from "@mui/system"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import Footer from "./Footer"
import Navbar from "./Navbar"

const StyledHero = styled(Box)(({ theme }) => ({
	backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg')`,
	backgroundSize: "cover",
	backgroundPosition: "center",
	color: theme.palette.primary.contrastText,
	padding: theme.spacing(8, 0, 6),
	textAlign: "center",
	minHeight: "100vh",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	position: "relative",
}))

const StyledCard = styled(Card)(({ theme }) => ({
	height: "auto",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	overflow: "hidden",
	position: "relative",
	zIndex: 1,
	borderRadius: "16px",
	boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
	transition: "all 0.3s ease-in-out",
}))

function CreditCardsPage() {
	const navigate = useNavigate()

	const handleApplyNow = () => {
		// Opens the IndusInd Samman Credit Card page in a new tab
		window.open(
			"https://www.indusind.com/in/en/personal/cards/credit-card/samman-credit-card.html?utm_source=Google_Organic_Search",
			"_blank",
			"noopener,noreferrer"
		)
	}

	return (
		<>
			<StyledHero>
				<Navbar />
				<Container maxWidth="xl">
					<Typography
						variant="h2"
						component="h1"
						gutterBottom
						sx={{
							fontFamily: '"Nato Sherif", serif',
							fontWeight: "bold",
							marginBottom: "1rem",
							fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
						}}>
						Samman Credit Card
					</Typography>
					<Typography
						variant="h5"
						paragraph
						sx={{
							marginBottom: "2rem",
							fontSize: {
								xs: "1rem",
								sm: "1.25rem",
								md: "1.5rem",
							},
						}}>
						Exclusively Designed For Government Employees
					</Typography>
				</Container>
			</StyledHero>

			<Box
				sx={{
					position: "relative",
					marginTop: { xs: "-50px", sm: "-75px", md: "-100px" },
					zIndex: 2,
					paddingX: { xs: 1, sm: 2 },
				}}>
				<Card
					sx={{
						maxWidth: "95%",
						margin: "0 auto",
						borderRadius: "20px",
						boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
						overflow: "visible",
						backgroundColor: "#d7eeff",
					}}>
					<CardContent
						sx={{
							padding: { xs: "1rem", sm: "1.5rem", md: "2rem" },
						}}>
						<Grid2
							container
							spacing={{ xs: 2, sm: 3, md: 4 }}
							justifyContent="center">
							<Grid2 item xs={12} md={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<StyledCard>
									<CardMedia
										component="img"
										height="200"
										width="100%"
										image="https://ik.imagekit.io/bankio/SammanCard.jpg?updatedAt=1733123619191"
										alt="Samman Credit Card"
										sx={{
											objectFit: "cover",
											objectPosition: "center",
										}}
									/>
								</StyledCard>
							</Grid2>
							<Grid2 item xs={12} md={6}>
								<Typography
									variant="h4"
									component="h2"
									gutterBottom
									sx={{
										fontFamily: '"Nato Sherif", sans-serif',
										fontWeight: "bold",
										color: "rgba(0, 48, 73, 1)",
										marginBottom: 2,
									}}>
									Card Features
								</Typography>
								<List>
									{[
										"Exclusive for Government Employees",
										"High Credit Limit",
										"Minimal Documentation",
										"Instant Approval",
										"No Hidden Charges",
									].map((feature, index) => (
										<ListItem key={index} disableGutters>
											<ListItemIcon>
												<CheckCircleOutlineIcon color="primary" />
											</ListItemIcon>
											<ListItemText primary={feature} />
										</ListItem>
									))}
								</List>
								<Button
									variant="contained"
									size="large"
									endIcon={<ArrowForwardIcon />}
									sx={{
										backgroundColor: "rgba(0, 48, 73, 1)",
										color: "#ffffff",
										borderRadius: "50px",
										padding: "10px 20px",
										marginTop: 2,
										"&:hover": {
											backgroundColor:
												"rgba(0, 48, 73, 0.8)",
										},
									}}
									onClick={handleApplyNow}>
									Apply Now
								</Button>
							</Grid2>
						</Grid2>
					</CardContent>
				</Card>
			</Box>

			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
					gap: 4,
					padding: 4,
				}}>
				<Box
					sx={{
						flex: 1,
						backgroundColor: "white",
						padding: 3,
						textAlign: "center",
					}}>
					<Typography
						variant="h3"
						component="h2"
						gutterBottom
						sx={{
							fontFamily: '"Nato Sherif", sans-serif',
							fontWeight: "bold",
							color: "rgba(0, 48, 73, 1)",
						}}>
						Why Choose Samman Credit Card?
					</Typography>
					<Typography
						variant="body1"
						paragraph
						sx={{ textAlign: "left" }}>
						Induslnd bank Samman Credit Card is meticulously crafted for
						government employees, offering unparalleled benefits
						tailored to your professional needs. With competitive
						rates, minimal documentation, and instant approval, we
						understand and respect your financial journey.
					</Typography>

					<Button
						variant="text"
						size="large"
						endIcon={<ArrowForwardIcon />}
						sx={{
							backgroundColor: "transparent",
							borderRadius: "50px",
							padding: "10px 20px",
							color: "rgba(0, 48, 73, 1)",
							fontWeight: "bold",
							fontFamily: '"Nato Sherif", sans-serif',
							"&:hover": {
								backgroundColor: "rgba(0, 48, 73, 1)",
								color: "#ffffff",
								borderRadius: "50px",
							},
						}}
						onClick={handleApplyNow}>
						Learn More
					</Button>
				</Box>

				<Box
					sx={{
						flex: 1,
						backgroundColor: "white",
						borderRadius: "10px",
						overflow: "hidden",
						boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
					}}>
					<Box
						component="img"
						sx={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
						alt="Credit Card Benefits"
						src="https://ik.imagekit.io/bankio/SammanCardBenifits.png?updatedAt=1733123876077"
					/>
				</Box>
			</Box>

			<Footer />
		</>
	)
}

export default CreditCardsPage
