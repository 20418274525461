import React from "react"
import { Button, Typography, Container, Paper } from "@mui/material"
import { FormInput } from "lucide-react"

const ComingSoonPage = () => {
	const handleGoogleForm = () => {
		window.open("https://forms.gle/3R146W9UuZrsAyCn7", "_blank")
	}

	return (
		<Container
			component="main"
			maxWidth={false}
			sx={{
				minHeight: "100vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				background: "linear-gradient(to bottom, #E3F2FD, #BBDEFB)",
				padding: 0,
			}}>
			<Paper
				elevation={3}
				sx={{
					padding: 4,
					textAlign: "center",
					maxWidth: "sm",
					width: "100%",
				}}>
				<Typography variant="h4" component="h1" gutterBottom>
					Coming Soon
				</Typography>

				<Typography variant="body1" color="textSecondary" gutterBottom>
					We're working on something exciting. Stay tuned!
				</Typography>

				{/* Google Form Button */}
				<Button
					variant="contained"
					color="primary"
					onClick={handleGoogleForm}
					sx={{ width: "100%", marginTop: 2 }}>
					<FormInput className="w-5 h-5" />
					Fill Our Form
				</Button>

				<Typography
					variant="body2"
					color="textSecondary"
					sx={{ marginTop: 2 }}>
					Share your interest by filling out our quick form
				</Typography>
			</Paper>
		</Container>
	)
}

export default ComingSoonPage
